body{
	margin: 0;
    padding: 0;
}

.border {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  z-index: 100;
  outline: 1px solid white;
  outline-offset: -1.5rem;
}

.border div {
  position: absolute;
  border: 10px solid rgba(0, 255, 0, 0.6);
}

.videoContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

#video_container {
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  min-width: 100%;
  overflow: hidden;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: white;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: white;
}

.is-invalid-input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: red;
  opacity: 1; /* Firefox */
}

.is-invalid-input:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: red;
}

.is-invalid-input::-ms-input-placeholder { /* Microsoft Edge */
  color: red;
}

.buttons {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 300;
    padding: 1.5rem;
    width: 25rem;
    box-sizing: border-box;
    text-align: center;
}

@media screen and (max-width: 39.9375em) {
    .buttons {
        width: 100%;
    }
}

button {
    border: 0px solid transparent;
    background: transparent;
    text-transform: uppercase;
    color: white;
    width: 100%;
    font-family:  "Times New Roman", "serif" !important;
    font-size: 1rem;
}

.signup-items, .social-items, .signup-thanks, .triggers {
    position: relative;
    float: right;
    width: 99%;
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: center;
    transition-timing-function: linear;
}

@media screen and (max-width: 39.9375em) {
    .signup-items, .social-items, .signup-thanks, .triggers {
        width: 100%;
    }
}

.triggers {
    width: 100%;
}

.signup-items, .social-items, .signup-thanks {
    display: none;
}

li {
    text-decoration: none;
    color: white;
    text-transform: uppercase;
    color: white;
    border: 1px solid white;
    background: transparent;
    padding: 1rem 0rem;
    text-transform: uppercase;
    color: white;
    border-radius: 5px;
    width: 100%;
    font-size: 1rem;

}

li a {
    text-decoration: none;
    color: white;
}

li:hover, li a:hover {
    font-style: italic;
}

li.trigger {
    position: relative;
    float: right;
    width: 49.3%;
}


@media screen and (max-width: 39.9375em) {
    li.trigger {
        width: 49.4%;
    }
}

.signup-items li {
    padding: .5rem 0rem;
}

input[type=text], .form input[type=text]:focus,
input[type=submit] {
    box-shadow: none;
    border: none;
    display: inline;
    background-color: transparent;
    text-align: center;
    text-transform: uppercase;
    font-family:  "Times New Roman", "serif" !important;
    color: white;
    height: 2rem;
    font-size: 1.25rem;
    margin: 0;
    padding: 0;
}

input[type=text].is-invalid-input, form input[type=text].is-invalid-input:focus {
    color: red;
}

.is-invalid-input:not(:focus) {
    border-color: transparent;
    background-color: transparent;
}

.buttons img {
    margin: 0px auto;
}

.vjs-big-play-button, .vjs-control-bar {
    display: none;
}

@media screen and (max-width: 39.9375em) {
    .player {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        overflow: hidden;
    }
    .vjs-control-bar {
        display: none;
    }
    .video-js.vjs-16-9, .video-js.vjs-4-3, .video-js.vjs-fluid {
        width: unset;
        max-width: unset;
        height: 100%;
    }
    .video-js .vjs-tech {
        position: absolute;
        top: 0;
        left: -100%;
        width: unset;
        height: 100%;
    }
}